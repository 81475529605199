import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import KatchLogo from "../../../assets/images/katch_logo";
import TopLeftLink from "../../../components/button/top-left-link.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import { register } from "../../../services/auth/auth-slice.service";
import SignUpForm from "../components/sign_up/sign-up-form.component";

const validationSchema = Yup.object().shape({
  method: Yup.string().required().label("Method"),
  firstName: Yup.string().required().min(2).label("First Name"),
  lastName: Yup.string().required().min(2).label("Last Name"),
  email: Yup.string().when("method", {
    is: "email",
    then: () => Yup.string().required().email().label("Email"),
  }),
  password: Yup.string().required().min(4).label("Password"),
  phoneNumber: Yup.string().when("method", {
    is: "phone",
    then: () => Yup.string().required().min(5).label("Phone"),
  }),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], "Password and confirm password must be same.")
    .label("Password confirmation"),
  termOfUse: Yup.boolean().oneOf([true], "Please read the terms of use"),
  newsletter: Yup.boolean(),
});

function SignUpScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [optForNewsletter, setOptForNewsletter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const createSnackBar = useContext(SnackbarContext);
  const formRef = useRef();

  const handleSubmitRegister = (values) => {
    setIsLoading(true);
    values.identifier = values.method === "email" ? values.email : values.phoneNumber;
    dispatch(register(values)).then(({ meta, error }) => {
      setIsLoading(false);
      if (meta.requestStatus === "fulfilled") {
        navigate(routes.PHONEVERIFICATIONCODE, {
          state: { method: values.method, identifier: values.identifier },
        });
      } else if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TopLeftLink label="< Back" pushTo={routes.LOGIN} />

      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 2 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                height: "100%",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              <Grid item xs={12}>
                <Form
                  innerRef={formRef}
                  initialValues={{
                    method: "email",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    password: "",
                    confirmPassword: "",
                    termOfUse: false,
                    newsletter: false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmitRegister}
                >
                  <SignUpForm
                    isLoading={isLoading}
                    optForNewsletter={optForNewsletter}
                    setOptForNewsletter={setOptForNewsletter}
                  />
                </Form>
              </Grid>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default SignUpScreen;
