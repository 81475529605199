import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import React from "react";
import FormCheckBox from "../../../../components/forms/form-checkbox.component";
import FormFieldTextWithIcon from "../../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormToggleButton from "../../../../components/forms/form-toggle-button.component";
import PhoneNumberTextField from "../../../../components/forms/phone-number-text-field.component";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import LabelLink from "../link-with-label.component";

function SignUpForm({ isLoading }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { values } = useFormikContext();

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: theme.palette.colors.bg.white,
          borderRadius: `${theme.shape.borderRadius[2]}px`,
          flex: 1,
          maxWidth: 500,
          minWidth: isMobile ? null : 450,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: 2,
          paddingTop: "10px",
        }}
      >
        <TopTitleBox title="Register" requireCloseButton={false} />

        <PaddedView multiples={isMobile ? 3 : 4}>
          <FormToggleButton
            fullWidth={false}
            options={[
              { label: "Email", value: "email" },
              { label: "Phone", value: "phone" },
            ]}
            name="method"
          />
          <Spacer size="m" />
          {values.method === "email" ? (
            <FormFieldTextWithIcon
              name="email"
              placeholder="Email"
              showIcon={true}
              icon={<MailOutlineIcon color="primary" />}
            />
          ) : (
            <PhoneNumberTextField name="phoneNumber" />
          )}

          <Spacer size="m" />
          <FormFieldTextWithIcon
            name="password"
            type="password"
            placeholder="Password"
            showIcon={true}
            icon={<LockOutlinedIcon color="primary" />}
          />
          <Spacer size="m" />
          <FormFieldTextWithIcon
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            showIcon={true}
            icon={<LockOutlinedIcon color="primary" />}
          />
          <Spacer size="m" />
          <FormFieldTextWithIcon
            name="firstName"
            placeholder="First Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon color="primary" />}
          />
          <Spacer size="m" />
          <FormFieldTextWithIcon
            name="lastName"
            placeholder="Last Name"
            showIcon={true}
            icon={<PersonOutlineOutlinedIcon color="primary" />}
          />
          <Grid container sx={{ paddingTop: 1.5 }}>
            <FormCheckBox
              name="termOfUse"
              label="By signing up, you acknowledge and agree with our terms of use"
            />
          </Grid>
          <Grid container sx={{ paddingTop: 1.5 }}>
            <FormCheckBox
              name="newsletter"
              label="Receive our newsletter for all the exciting perks and recommendations via email"
            />
          </Grid>
          <Spacer size="l" />
          <LabelLink label="Already have an account? " link="Sign In" pushTo={routes.LOGIN} />
          <Spacer size="s" />
          <Grid item textAlign="center" xs={12}>
            <FormSubmitButton
              disabled={values.termOfUse === false}
              isLoading={isLoading}
              width="60%"
              borderRadius={3}
            >
              <Typography>Sign Up</Typography>
            </FormSubmitButton>
          </Grid>
        </PaddedView>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default SignUpForm;
